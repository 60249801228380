import React, { useRef, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from "@mui/material";
import EnvelopeIcon from '@assets/icons/envelope_secondary.svg';
import PhoneIcon from '@assets/icons/phone_secondary.svg';
import TimeIcon from '@assets/icons/time_secondary.svg';
import FacebookIcon from '@assets/icons/facebook_secondary.svg';
import clsx from 'clsx';

const PREFIX = 'ContactList';

const classes = {
  ul: `${PREFIX}-ul`,
  li: `${PREFIX}-li`,
  link: `${PREFIX}-link`,
  icon: `${PREFIX}-icon`,
  envelope: `${PREFIX}-envelope`,
  time: `${PREFIX}-time`,
  phone: `${PREFIX}-phone`,
  facebook: `${PREFIX}-facebook`,
  liText: `${PREFIX}-liText`,
  image: `${PREFIX}-image`
};

const Root = styled('ul')((
  {
    theme
  }
) => ({
  [`&.${classes.ul}`]: {
    margin: 0,
    padding: 0,
    // marginLeft: variant === 'inline' ? theme.spacing(1) / 2 : 0,
    alignItems: 'center',
    flexDirection: 'row-reverse', //works only if display: flex
    position: 'relative',
    listStyleType: 'none',
  },
  [`& .${classes.li}`]: {
    color: theme.palette.secondary.main,
    padding: 0,
    margin: theme.spacing(2),
    marginLeft: 0,
    fontFamily: 'Prompt !important',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 14,
    '&:last-child': {
      marginRight: 0,
    }
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    paddingLeft: theme.spacing(1/2),
    '& span': {
      lineHeight: 1.6,
      verticalAlign: 'bottom',
    },
    '&:hover': {
      color: theme.palette.common.white,
      '& svg': {
        fill: theme.palette.common.white,
      }
    }
  },

  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(1 / 2),
    width: 14,
    height: 14,
  },

  [`& .${classes.envelope}`]: {
    fontSize: 15, //just the customer request
    '& span': {
      [theme.breakpoints.up('md')]: {
        display: 'inline !important',
      }
    },
  },

  [`& .${classes.time}`]: {
    '& :hover': {
      color: theme.palette.secondary.main,
    }
  },

  [`& .${classes.phone}`]: {
  },

  [`& .${classes.facebook}`]: {
    marginRight: 0,
    left: 155, //should be replaced by penultimateLiWidth, but it doesn't work for now
    bottom: `-${theme.spacing(2)}`,
    order: -1, //works only for ul display: flex
    '& span': {
      display: 'none'
    }
  },

  [`& .${classes.liText}`]: {
    fontFamily: 'Prompt !important',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: 14,
  },

  [`& .${classes.image}`]: {
    width: '100%',
  }
}));


function ContactList({ contactInfo, variant, justify }) {
  const penultimateLiElement = useRef(null);
  const [penultimateLiWidth, setPenultimateLiWidth] = useState(0);

  const matches420 = useMediaQuery('(max-width: 420px)');
  const matches400 = useMediaQuery('(max-width: 400px)');
  const matchesDesktop = useMediaQuery('(min-width:960px)');

  useEffect(() => {
    if(penultimateLiElement.current) {
      setPenultimateLiWidth(penultimateLiElement.current.clientWidth)
    }
  }, [penultimateLiElement.current?.clientWidth]);

  return (
    <Root 
      aria-label="contact us" 
      className={classes.ul} 
      sx={{ justifyContent: justify, display: variant !== 'inline' ? 'block' : 'flex', }}
    >
      {
        matches420 && variant === 'inline' ?
          null :
          <li className={clsx(classes.li, classes.envelope)}>
            <a href={`mailto:${contactInfo.email}`} className={clsx(classes.link)} aria-label="email">
              <EnvelopeIcon className={classes.icon}/>
              <span style={{
                  display: variant !== 'inline' ? 'inline' : 'none',
                }}
              >
                {contactInfo.email}
              </span>
            </a>
          </li>
      }
      {
        variant === 'inline' && !matchesDesktop ?
        null :
        <li className={clsx(classes.li, classes.time)}>
          <p className={clsx(classes.link)}>
            <TimeIcon className={classes.icon}/>
            <span>{contactInfo.workingTime}</span>
          </p>
      </li>
      }
      <li ref={penultimateLiElement} className={clsx(classes.li, classes.phone)}>
        <a href={`callto:${contactInfo.phone}`} className={clsx(classes.link)} aria-label="phone">
          <PhoneIcon className={classes.icon}/>
          <span>{contactInfo.phoneText}</span>
        </a>
      </li>
      {
        matches400 && variant === 'inline' ?
        null :
        <li className={clsx(classes.li, classes.facebook)} style={{ position: variant !== 'inline' ? 'absolute' : 'static' }}>
          <a href={contactInfo.facebookLink} className={clsx(classes.link)} target="_blank" aria-label="facebook" rel="noreferrer noopener">
            <FacebookIcon className={classes.icon}/>
            <span>our facebook link</span>
          </a>
        </li>
      }
    </Root>
  );
}

export default ContactList;