import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    accent: '#EC5E0F',
    primary: {
      main: '#1b4521',
      dark: '#152B18'
    },
    secondary: {
      main: '#E7B607',
    },
    background: {
      grey: '#e5e5e5',
      transparent: 'rgba(255, 255, 255, 0.64)',
      transparentGrey: 'rgba(21, 43, 24, 0.25)',
      default: '#ffffff',
    },
    text: {
      primary: '#1b4521',
      secondary: '#FFC800',
      disabled: 'rgba(27, 69, 33, 0.56)',
      accent: '#EC5E0F',
    },
    type: 'light'
  },
  typography: {
    fontFamily: ["Prompt", "Helvetica", "Arial", "sans-serif"].join(','),
    h1: {
      fontFamily: ["Prompt", "Helvetica", "Arial", "sans-serif"].join(','),
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '123%',
      textTransform: 'uppercase',
      '@media (min-width:600px)': {
        fontSize: '38px',
        lineHeight: '57px',
      },
    },
    h2: {
      fontFamily: 'Prompt',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '123%',
      '@media (min-width:600px)': {
        fontSize: '44px',
        lineHeight: '57px',
        fontWeight: 600,
      },
    },
    h3: { //now used for Offers component subtitles (desktop)
      fontFamily: 'Krub',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '26px',
    },
    h4: {
      fontFamily: ["Krub", "Helvetica", "Arial", "sans-serif"].join(','),
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 17,
      lineHeight: '145.1%',
      '@media (min-width:600px)': {
        fontSize: 20,
        lineHeight: '24px',
      },
    },
    body1: {
      fontFamily: ["Prompt", "Helvetica", "Arial", "sans-serif"].join(','),
      fontSize: 14,
      fontWeight: 300,
      '@media (min-width:600px)': {
        fontSize: 18,
        lineHeight: '27px',
      },
    },
    body2: {
      fontFamily: ["Krub", "Helvetica", "Arial", "sans-serif"].join(','),
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '148.1%',
    },
    
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#E7B607'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Prompt",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 12,
          paddingTop: 10,
          paddingBottom: 10,
          borderRadius: 5
        },
        containedSecondary: {
          color: '#ffffff'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: '#152B18',
        }
      }
    }
  }
});

export default theme;