import React from 'react';
import { styled } from '@mui/material/styles';
import { Menu, MenuItem, Badge } from '@mui/material';
import { navigate } from 'gatsby'
import clsx from 'clsx'

const PREFIX = 'MobileMenu';

const classes = {
  listItem: `${PREFIX}-listItem`,
  link: `${PREFIX}-link`,
  badge: `${PREFIX}-badge`,
  mobileMenuBadge: `${PREFIX}-mobileMenuBadge`
};

const StyledMenu = styled(Menu)((
  {
    theme
  }
) => ({
  //menu view
  [`& .${classes.listItem}`]: {
    color: '#ffffff !important',
    fontFamily: 'Krub',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    lineHeight: 3,
    textTransform: 'uppercase',
    textShadow: '0px 4px 4px rgba(27, 69, 33, 0.25)',
  },

  [`& .${classes.link}`]: {
    display: 'block',
    boxSizing: 'border-box',
    color: theme.palette.common.white,
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontFamily: 'Krub',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    border: '1px solid transparent',
    transition: 'all 0.03s',
    padding: theme.spacing(1/4, 1/2),
    '&:hover': {
      border: '1px solid #E7B607'
    }
  },

  [`& .${classes.badge}`]: {
    backgroundColor: theme.palette.accent,
  },

  [`& .${classes.mobileMenuBadge}`]: {
    top: theme.spacing(1.5),
    right: theme.spacing(-1.5)
  }
}));

function MobileMenu({ anchorEl, onClose, open, navigationMenu, path, myPriceList }) {

  const handleMenuClick = (link) => {
    navigate(link)
    onClose()
  }

  return (
    <StyledMenu
    role="navigation"
    slotProps={{
      paper: {
        style: {
          //backgroundColor overrided in the theme
          borderRadius: 0,
          top: '65px !important',
        }
      }
    }}
    aria-label="main navigation"
    anchorEl={anchorEl}
    onClose={onClose}
    open={open}>
      {
        navigationMenu.map(menuItem => {
          return (
              <MenuItem
              color='white'
              key={menuItem.link}
              selected={path.includes(menuItem.link)}
              classes={{ root: classes.listItem }}
              onClick={() => handleMenuClick(menuItem.link)}
              >
                <Badge
                key={menuItem.link}
                overlap='rectangular'
                badgeContent={myPriceList.length}
                invisible={menuItem.link !== '/my-price-list'}
                classes={{ badge: clsx(classes.badge, classes.mobileMenuBadge)}}>
                  { menuItem.caption }
                </Badge>
              </MenuItem>
          )
        })
      }
    </StyledMenu>
  );
}

export default MobileMenu;