exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-price-lists-index-js": () => import("./../../../src/pages/all-price-lists/index.js" /* webpackChunkName: "component---src-pages-all-price-lists-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-incline-conveyors-js": () => import("./../../../src/pages/incline-conveyors.js" /* webpackChunkName: "component---src-pages-incline-conveyors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-price-list-js": () => import("./../../../src/pages/my-price-list.js" /* webpackChunkName: "component---src-pages-my-price-list-js" */),
  "component---src-templates-models-archive-js": () => import("./../../../src/templates/models-archive.js" /* webpackChunkName: "component---src-templates-models-archive-js" */),
  "component---src-templates-modifications-archive-js": () => import("./../../../src/templates/modifications-archive.js" /* webpackChunkName: "component---src-templates-modifications-archive-js" */),
  "component---src-templates-product-variant-card-js": () => import("./../../../src/templates/product-variant-card.js" /* webpackChunkName: "component---src-templates-product-variant-card-js" */)
}

