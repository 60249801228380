import React, { useState } from 'react'

const HeaderHeightStateContext = React.createContext()
const HeaderHeightSetContext = React.createContext()

function HeaderHeightProvider({children}) {
  const [headerHeight, setHeaderHeight] = useState(0)

  return (
    <HeaderHeightStateContext.Provider value={headerHeight}>
      <HeaderHeightSetContext.Provider value={setHeaderHeight}>
        {children}
      </HeaderHeightSetContext.Provider>
    </HeaderHeightStateContext.Provider>
  )
}
export {HeaderHeightProvider, HeaderHeightStateContext, HeaderHeightSetContext }