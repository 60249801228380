import React from 'react';
import { styled } from '@mui/material/styles';
const PREFIX = 'Designers';

const classes = {
  designersParagraph: `${PREFIX}-designersParagraph`
};

const Root = styled('p')((
  {
    theme
  }
) => ({
  [`&.${classes.designersParagraph}`]: {
    margin: 0,
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontSize: 14,
    lineHeight: 1.6,
    verticalAlign: 'bottom',
    color: theme.palette.primary.main,
    "& a": {
      color: `${theme.palette.primary.main} !important`,
    }
  }
}));

function Designers() {

  
  return (
    <Root className={classes.designersParagraph}>
      Developed by <a href="https://linkedin.com/in/fiodar-savutsin/" target="_blank" rel="noreferrer noopener"> Fiodar Savutsin</a>
      <br />
      {/* ⚪🔴⚪ */}
    </Root>
  );
}

export default Designers;