import React from "react";
import Providers from '@components/TopLayout/TopLayout'
import ThemeTopLayout from "gatsby-theme-material-ui-top-layout/src/components/top-layout";

export default function TopLayout({ children, theme }) {
  return (
    <Providers>
      <ThemeTopLayout theme={theme}>
        {children}
      </ThemeTopLayout>
    </Providers>
  );
}