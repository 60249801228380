import React, { useContext, useState, useMemo } from "react"
import { styled } from '@mui/material/styles';
import Header from "./components/Header"
import Footer from "./components/Footer"
import { useStaticQuery, graphql } from "gatsby"
import { HeaderHeightStateContext } from '../../context/headerHeightContext'
import clsx from 'clsx'
import background from '../../images/FEP.svg'

const PREFIX = 'Layout';

const classes = {
  root: `${PREFIX}-root`,
  mainTag: `${PREFIX}-mainTag`,
  fepBackground: `${PREFIX}-fepBackground`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },

  [`&.${classes.fepBackground}`]: {
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#f7f7f7',
      backgroundPosition: 'bottom -33px left calc(50% + 120px)',
    }
  },

  [`& .${classes.mainTag}`]: {
    flexGrow: 1,
    minHeight: '100vh' //to fix bouncing header problem
  },
}));

const Layout = ({ location, children }) => {

  const {
    wp: {
      generalSettings: { title },
    },
    allWpContactInfoItem: { 
      settingList: [ firstSettingGroup ] 
    },
    logoOld
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
      allWpContactInfoItem(limit: 1) {
        settingList: nodes {
          contact_info {
            addressLink
            addressLinkExternal
            addressText
            facebookLink
            instagramLink
            instagramName
            youtubeLink
            youtubeName
            email
            fieldGroupName
            phone
            phoneText
            workingTime
          }
        }
      }
      logoOld: file(relativePath: {eq: "logo-old.png"}) {
        publicURL
      }
    }
  `)
  const { contact_info: contactInfo } = firstSettingGroup
  const [ scrolled, setScrolled ] = useState(false);
  const headerHeight = useContext(HeaderHeightStateContext)

  const isHomePage = useMemo(() => location.pathname === '/', [location])


  return (
    <Root 
      data-is-root-path={isHomePage} 
      className={clsx(classes.root, !isHomePage && classes.fepBackground)}
    >
      <Header contactInfo={contactInfo} logoOld={logoOld.publicURL} location={location} scrolled={scrolled} setScrolled={setScrolled} />

      <main className={classes.mainTag} style={{
        marginTop: (!isHomePage && scrolled) ? headerHeight : 0,
      }}>
        { children }
      </main>

      <Footer contactInfo={contactInfo} logoOld={logoOld.publicURL}/>
    </Root>
  );
}

export default Layout
