import React, { useState } from 'react'

const SeenProductsStateContext = React.createContext()
const SeenProductsSetContext = React.createContext()

function SeenProductsProvider({children}) {
  const [seenProducts, setSeenProducts] = useState([])

  return (
    <SeenProductsStateContext.Provider value={seenProducts}>
      <SeenProductsSetContext.Provider value={setSeenProducts}>
        {children}
      </SeenProductsSetContext.Provider>
    </SeenProductsStateContext.Provider>
  )
}
export { SeenProductsProvider, SeenProductsStateContext, SeenProductsSetContext }