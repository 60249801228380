import React, { useState, useEffect } from 'react'

const MyPriceListStateContext = React.createContext()
const MyPriceListSetContext = React.createContext()

function MyPriceListProvider({children}) {
  const [myPriceList, setMyPriceList] = useState([]);

  useEffect(() => {
    try {
      if (typeof window !== 'undefined') {
        console.log(process.env)
        const localData = localStorage.getItem('myPriceList');
        setMyPriceList(localData ? JSON.parse(localData) : []);
      }
    } catch (error) {}
  }, [])

  useEffect(() => {
    try {
      if (typeof window !== 'undefined') {
        localStorage.setItem('myPriceList', JSON.stringify(myPriceList))
      }
    } catch (error) {}
  }, [myPriceList])

  return (
    <MyPriceListStateContext.Provider value={myPriceList}>
      <MyPriceListSetContext.Provider value={setMyPriceList}>
        {children}
      </MyPriceListSetContext.Provider>
    </MyPriceListStateContext.Provider>
  )
}

export { MyPriceListProvider, MyPriceListStateContext, MyPriceListSetContext }