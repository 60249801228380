import React, { useState } from 'react'

const FooterHeightStateContext = React.createContext()
const FooterHeightSetContext = React.createContext()

function FooterHeightProvider({children}) {
  const [footerHeight, setFooterHeight] = useState(0)

  return (
    <FooterHeightStateContext.Provider value={footerHeight}>
      <FooterHeightSetContext.Provider value={setFooterHeight}>
        {children}
      </FooterHeightSetContext.Provider>
    </FooterHeightStateContext.Provider>
  )
}
export {FooterHeightProvider, FooterHeightStateContext, FooterHeightSetContext }