import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import EnvelopeIcon from '@assets/icons/envelope_secondary.svg';
import PhoneIcon from '@assets/icons/phone_secondary.svg';
import TimeIcon from '@assets/icons/time_secondary.svg';
import FacebookIcon from '@assets/icons/facebook_secondary.svg';
import AddressIcon from '@assets/icons/address_primary.svg';
import InstagramIcon from '@assets/icons/instagram_secondary.svg';
import YoutubeIcon from '@assets/icons/youtube_secondary.svg';

const UlMobile = styled('ul')({
  margin: 0,
  padding: 0,
  // marginLeft: theme.spacing(1) / 2,
  display: 'flex',
  justifyContent: 'space-between',
  listStyleType: 'none',
});

const UlDesktop = styled(Grid)({
  margin: 0,
  padding: 0,
  // marginLeft: theme.spacing(1) / 2,
  display: 'flex',
  justifyContent: 'space-between',
  listStyleType: 'none',
})

const GridItem = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%'
});

const Li = styled('li')(({ theme }) => ({
  listStyleType: 'none',
  color: theme.palette.primary.main,
  padding: 0,
  margin: 0,
}))

const LinkStyled = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  paddingLeft: theme.spacing(1) / 2,
  '& span': {
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontSize: 14,
    lineHeight: 1.6,
    verticalAlign: 'bottom',
  },
  '& svg': {
    fill: theme.palette.primary.main,
  },
  '&:hover': {
    color: theme.palette.common.white,
    '& svg': {
      fill: theme.palette.common.white,
    }
  }
}))

const IconText = styled('span')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up("md")]: {
    display: 'inline',
  }
}))
const IconTextFacebook = styled('span')(({ theme }) => ({
  display: 'none',
}))

const iconStyles = {
  marginRight: '4px',
  width: '14px',
  height: '14px',
};


function ContactList({ contactInfo, matchesDesktop }) {

  if(matchesDesktop) {
    return (
      <UlDesktop component="ul" container spacing={2}>
        <GridItem item md={6}>
          <Li>
            <LinkStyled href={contactInfo.addressLinkExternal} target="_blank" rel="noreferrer noopener" aria-label="address">
              <AddressIcon style={iconStyles} />
              <IconText>{contactInfo.addressText}</IconText>
            </LinkStyled>
          </Li>
        </GridItem>
        <GridItem item md={6}>
          <Li>
            <LinkStyled 
              href={`callto:${contactInfo.phone}`} 
              sx={{ marginBottom: (theme) => theme.breakpoints.up("md") ? theme.spacing(2) : undefined }} 
              aria-label="phone"
            >
              <PhoneIcon style={iconStyles} />
              <IconText>{contactInfo.phoneText}</IconText>
            </LinkStyled>
          </Li>
        </GridItem>
        <GridItem item md={6}>
          <Li>
            <LinkStyled href={`mailto:${contactInfo.email}`} aria-label="email">
              <EnvelopeIcon style={iconStyles} />
              <IconText>{contactInfo.email}</IconText>
            </LinkStyled>
          </Li>
        </GridItem>
        <GridItem item md={6}>
          <Li>
            <LinkStyled href={`mailto:${contactInfo.instagramLink}`} aria-label="instagram">
              <InstagramIcon style={iconStyles} />
              <IconText>{contactInfo.instagramName}</IconText>
            </LinkStyled>
          </Li>
        </GridItem>
        <GridItem item md={6}>
          <Li>
            <LinkStyled href={`mailto:${contactInfo.youtubeLink}`} aria-label="youtube">
              <YoutubeIcon style={iconStyles} />
              <IconText>{contactInfo.youtubeName}</IconText>
            </LinkStyled>
          </Li>
        </GridItem>
      </UlDesktop>
    )
  }

  return (
    <UlMobile aria-label="contact us">
      <Li>
        <LinkStyled href={`mailto:${contactInfo.email}`} aria-label="email">
          <EnvelopeIcon style={iconStyles} />
          <IconText>{contactInfo.email}</IconText>
        </LinkStyled>
      </Li>
      <Li sx={(theme) => ({
        display: 'none',
        [theme.breakpoints.up("md")]: { display: 'list-item' },
      })} >
        <LinkStyled>
          <TimeIcon style={iconStyles} />
          <IconText>{contactInfo.workingTime}</IconText>
        </LinkStyled>
      </Li>
      <Li>
        <LinkStyled 
          href={`callto:${contactInfo.phone}`} 
          aria-label="phone"
          sx={(theme) => ({ [theme.breakpoints.up("md")]: { marginBottom: theme.spacing(2) } })} 
        >
          <PhoneIcon style={iconStyles} />
          <IconText>{contactInfo.phoneText}</IconText>
        </LinkStyled>
      </Li>
      <Li>
        <LinkStyled href={contactInfo.addressLinkExternal} target="_blank" rel="noreferrer noopener" aria-label="address">
          <AddressIcon style={iconStyles} />
          <IconText>{contactInfo.addressText}</IconText>
        </LinkStyled>
      </Li>
      <Li>
        <LinkStyled href={contactInfo.facebookLink} target="_blank" aria-label="facebook" rel="noreferrer noopener">
          <FacebookIcon style={iconStyles} />
          <IconTextFacebook>our facebook link</IconTextFacebook>
        </LinkStyled>
      </Li>
      <Li>
        <LinkStyled href={contactInfo.instagramLink} target="_blank" aria-label="facebook" rel="noreferrer noopener">
          <InstagramIcon style={iconStyles} />
          <IconText>our instagram link</IconText>
        </LinkStyled>
      </Li>
      <Li>
        <LinkStyled href={contactInfo.youtubeLink} target="_blank" aria-label="facebook" rel="noreferrer noopener">
          <YoutubeIcon style={iconStyles} />
          <IconText>our youtube link</IconText>
        </LinkStyled>
      </Li>
    </UlMobile>
  );
}

export default ContactList;